import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Navbar } from "../components/NavBar";
import { Footer } from "../components/Footer";

export const Branding = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <Navbar />
            <div className="ld-content-1 h-screen bg-lore-400 text-white text-center gap-5 flex flex-col md:flex-row justify-center items-center px-4 sm:px-8 md:px-16 lg:px-32 xl:px-48 2xl:px-64">
                <h1 data-aos="fade-right" data-aos-duration="1000" className="font-dmserif text-4xl tracking-widest">BRAND</h1>
                <img data-aos="zoom-in" data-aos-duration="2000" className="h-96" src={require("../assets/img/lorenika_default.png")} alt="Lorenika Branding" />
                <h1 data-aos="fade-left" data-aos-duration="1000" className="font-dmserif text-4xl tracking-widest">IDENTITY</h1>
            </div>
                {/* <div className="ld-content-1 h-screen bg-lore-light-100 text-white text-center gap-5 flex flex-col md:flex-row justify-center items-center px-4 sm:px-8 md:px-16 lg:px-32 xl:px-48 2xl:px-64">
                        <p className="font-dmsans text-xl">
                            lore [lawr, lohr]:<br />
                            noun<br />
                            1. The body of knowledge, especially of a traditional, anecdotal, or popular nature, on a particular subject.<br />
                            2. The underlying story or mythology behind a brand or product.
                        </p>
                        <p className="font-dmsans text-xl mt-4">
                            nika [nee-kuh]:<br />
                            noun<br />
                            1. A representation of victory or success.<br />
                            2. A symbol of achievement and excellence.
                        </p>
                </div> */}
            <div className="ld-content-1 h-screen bg-lore-light-100 text-white text-center gap-5 flex flex-col md:flex-row justify-center items-center px-4 sm:px-8 md:px-16 lg:px-32 xl:px-48 2xl:px-64">
                <img className="md:h-5/6 h-auto" src={require("../assets/img/philo-1.png")} alt="Philosophy 1" />
            </div>
            <div className="ld-content-1 h-screen bg-lore-light-100 text-white text-center gap-5 flex flex-col md:flex-row justify-center items-center px-4 sm:px-8 md:px-16 lg:px-32 xl:px-48 2xl:px-64">
                <img className="md:h-5/6 h-auto" src={require("../assets/img/philo-2.png")} alt="Philosophy 2" />
            </div>
            <Footer />
        </>
    )
}

import React, {useRef, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

const Divider = () => (
    <div className="divider md:py-8 p-4">
      <div className="md:border-r-2 md:border-r-primary md:h-full border-b-2 border-b-primary w-full"></div>
    </div>
);

export const Footer = () => {
    return (
        <>
            <div className="bg-lore-dark-950 px-4 md:px-20 py-16">
                <div className="flex lg:flex-row flex-col justify-between">
                    <div className="flex flex-col gap-3">
                        <img className="h-12 mr-auto" src={require("../assets/img/logo-text-white.png")} alt="" />
                        <div className="text-white font-dmsans flex gap-3">
                            <img className="pl-1" src={require("../assets/img/indonesia.png")} alt="" />
                            <p>Indonesia</p>
                        </div>
                        <div className="flex gap-3 mb-4 text-white text-2xl">
                            <a href="https://www.instagram.com/lorenika.pkm24/"><FaInstagram /></a>
                            <a href="https://www.linkedin.com/company/klauzzai"><FaLinkedin/></a>
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row gap-10 lg:gap-10 text-center lg:text-left text-white font-dmsans">
                        <ul className="flex flex-col gap-2 lg:gap-4">
                            <li className="text-[#D9D9D9]">Produk</li>
                            <li><a href="https://drive.google.com/file/d/1ldWDm75QDs1R21qwWrp-mk6PkxbXrh6g/view">Lorenika App</a></li>
                            <li><a href="/chat">Lorenika Free Chat</a></li>
                        </ul>
                        <ul className="flex flex-col gap-2 lg:gap-4">
                            <li className="text-[#D9D9D9]">Tentang Kami</li>
                            <li><a href="/team">Tim</a></li>
                            <li><a href="/branding">Branding</a></li>
                        </ul>
                        <ul className="flex flex-col gap-2 lg:gap-4">
                            <li className="text-[#D9D9D9]">Privasi dan ketentuan</li>
                            <li><a href="/privacy">Privasi</a></li>
                            <li><a href="/security">Keamanan</a></li>
                            <li><a href="/feedback">Feedback</a></li>
                        </ul>
                    </div>
                </div>
                <Divider />
                <div className="text-white text-center font-dmsans">@ 2024 KlauzzAI PKM-KI</div>
            </div>
        </>
    )
}
import React from "react";
import { Navbar } from "../components/NavBar";
import { Footer } from "../components/Footer";

export const Security = () => {
    return (
        <>
            <Navbar />
            <div className="flex flex-col bg-lore-light-100">
                <div className="bg-lore-dark-950 pt-28 px-28">
                    <h1 className="text-4xl font-normal text-center mb-8 font-dmserif tracking-wider text-white">Keamanan</h1>
                </div>
                
                <div className="px-5 md:px-28 pt-10">
                <section className="mb-8">
                    <h2 className="font-dmserif font-normal text-2xl mb-4">Pendahuluan</h2>
                    <p className="font-dmsans">
                        Di Lorenika, kami mengutamakan keamanan Anda. Kami berkomitmen untuk melindungi informasi pribadi Anda dan memastikan data Anda tetap aman. Dibangun menggunakan Flutter dan Firebase, aplikasi kami memanfaatkan praktik keamanan terdepan untuk menjaga informasi Anda tetap aman.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif font-normal text-2xl mb-4">Enkripsi Data</h2>
                    <p className="font-dmsans">
                        Kami menggunakan metode enkripsi canggih untuk mengamankan data Anda baik saat pengiriman maupun penyimpanan. HTTPS memastikan transmisi data yang aman antara perangkat Anda dan server kami. Firebase Firestore dan Realtime Database mengenkripsi data Anda, memberikan perlindungan yang kuat terhadap akses yang tidak sah.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif font-normal text-2xl mb-4">Kontrol Akses</h2>
                    <p className="font-dmsans">
                        Kami menerapkan kontrol akses ketat menggunakan aturan keamanan Firebase Firestore dan klaim kustom untuk peran pengguna di Firebase Authentication. Hal ini memastikan bahwa hanya pengguna yang berwenang yang dapat mengakses data tertentu, menjaga integritas dan kerahasiaan informasi Anda.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif font-normal text-2xl mb-4">Privasi Data</h2>
                    <p className="font-dmsans">
                        Privasi Anda sangat penting bagi kami. Kami mengumpulkan, menggunakan, dan menyimpan data Anda sesuai dengan GDPR, CCPA, dan peraturan relevan lainnya. Anda memiliki hak untuk mengakses, memperbaiki, dan menghapus data Anda sesuai kebutuhan.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif font-normal text-2xl mb-4">Audit Keamanan Berkala</h2>
                    <p className="font-dmsans">
                        Kami melakukan audit dan penilaian keamanan secara berkala untuk mengidentifikasi dan mengatasi potensi kerentanan. Aturan Keamanan Firebase membantu kami memantau dan memperbarui langkah-langkah keamanan secara terus-menerus.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif font-normal text-2xl mb-4">Respon Insiden</h2>
                    <p className="font-dmsans">
                        Dalam hal terjadi pelanggaran keamanan, kami memiliki tim respon insiden yang siap bertindak cepat. Anda dapat menghubungi tim kami di <a href="/feedback">Umpan Balik</a> untuk setiap kekhawatiran atau masalah terkait keamanan atau lainnya.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif font-normal text-2xl mb-4">Tanggung Jawab Pengguna</h2>
                    <p className="font-dmsans">
                        Untuk melindungi akun Anda lebih lanjut, kami menganjurkan Anda untuk menggunakan kata sandi yang kuat. Jaga informasi login Anda tetap aman dan hindari membagikannya dengan orang lain.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif font-normal text-2xl mb-4">Kepatuhan</h2>
                    <p className="font-dmsans">
                        Firebase mematuhi standar dan peraturan keamanan global, termasuk ISO/IEC 27001 dan SOC 2. Untuk informasi lebih lanjut tentang kepatuhan Firebase, kunjungi halaman <a href="https://firebase.google.com/support/privacy" className="text-blue-500">Keamanan dan Privasi Firebase</a>.
                    </p>
                </section>
                
                {/* <section className="mb-8">
                    <h2 className="font-dmserif font-normal text-2xl mb-4">Informasi Kontak</h2>
                    <p className="font-dmsans">
                        Jika Anda memiliki pertanyaan atau kekhawatiran tentang keamanan, silakan hubungi tim keamanan kami di [email_kontak_keamanan]. Kami di sini untuk membantu memastikan data Anda tetap aman dan terjamin.
                    </p>
                </section> */}
                </div>
            </div>
            <Footer />
        </>
    );
}

import React, { useEffect } from "react";
import { FaInstagram, FaLinkedin, FaGithub } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Navbar } from "../components/NavBar";
import { Footer } from "../components/Footer";
import imgAnggota1 from '../assets/img/anggota1.jpg';
import imgAnggota2 from '../assets/img/anggota2.jpg';
import imgDosen from '../assets/img/dosen.jpg';
import imgKetua from '../assets/img/ketua.jpeg';
import imgAnggota3 from '../assets/img/anggota3.png';
import imgAnggota4 from '../assets/img/anggota4.jpg';

const teamMembers = [
    {
        id: 1,
        name: "Dhira Ramadini",
        role: "UI Designer • Front-End Developer",
        imgSrc: imgAnggota1,
        instagram: "https://www.instagram.com/dirarmdn/",
        linkedin: "https://www.linkedin.com/in/dhiraramadini/",
        github: "https://github.com/dirarmdn"
    },
    {
        id: 2,
        name: "Athalie Aurora P",
        role: "Project Manager • Marketing",
        imgSrc: imgAnggota2,
        instagram: "https://www.instagram.com/rathaavle/",
        linkedin: "https://www.linkedin.com/in/athalie-aurora",
        github: "https://github.com/athalie-aurora"
    },
    {
        id: 3,
        name: "Lukmannul Hakim Firdaus S.Kom, M.T.",
        role: "Supervisor",
        imgSrc: imgDosen,
        instagram: "https://www.instagram.com/elhakimfirdaus/",
        linkedin: "https://www.linkedin.com/in/elhakimfirdaus/"
    },
    {
        id: 4,
        name: "Rafka Imanda P",
        role: "Leader • AI Developer",
        imgSrc: imgKetua,
        instagram: "https://www.instagram.com/raff_imanda/",
        linkedin: "https://www.linkedin.com/in/rafka-imanda-putra",
        github: "https://github.com/rafka-imandaputra"
    },
    {
        id: 5,
        name: "Garly Nugraha",
        role: "Public Relation • ",
        imgSrc: imgAnggota4,
        instagram: "https://www.instagram.com/garlynugrahaa/",
        linkedin: "https://www.linkedin.com/in/garlynugrahaa/",
        github: "https://github.com/garlynugrahaa"
    },
    {
        id: 6,
        name: "Rika Nurul F",
        role: "Finance & Accounting Manager",
        imgSrc: imgAnggota3,
        instagram: "https://www.instagram.com/rikaa.nnrrllll/",
        linkedin: "https://www.linkedin.com/in/rikanurulfatah/"
    }
];

const TeamMember = ({ member }) => (
    <div className={`flex flex-col mb-auto justify-center items-center ${member.id % 2 === 0  ? 'md:mt-14' : ''}`} data-aos={member.id % 2 !== 0 ? "fade-up" : "fade-down"} data-aos-duration="2000">
        <div className={`w-48 h-52 md:h-96 overflow-hidden rounded-2xl ${member.name === 'Rika Nurul F' ? 'bg-white' : ''}`}>
            <img className={`scale-150 ${member.id === 3 ? 'md:scale-[2] pt-16' : ''} ${member.id === 1 ? 'md:scale-[1.8] pt-3 md:pt-16' : ''} ${member.id === 2 ? 'md:scale-[2.2] pt-3 md:pt-16 md:pr-3' : ''} ${member.id === 4 ? 'md:scale-[2.2] pt-3 md:pt-16' : ''} ${member.id === 5 ? 'scale-[3] pt-7 md:pt-10 pr-5' : 'pt-3 pl-3'} ${member.id === 6 ? 'scale-[4] md:pt-2 pr-3' : 'pt-3 pl-3'}`} src={member.imgSrc} alt={member.name} />
        </div>
        <h1 className="font-dmsans text-center mt-2 text-sm">{member.name}</h1>
        <p className="text-xs text-center font-dmsans tracking-wide">{member.role}</p>
        <div className="socmed flex text-lore-400 gap-2 justify-center items-center mt-1">
            {member.instagram && <a className="hover:text-lore-dark-950 transform transition duration-300 ease-in-out hover:shadow-lg" href={member.instagram}><FaInstagram /></a>}
            {member.linkedin && <a className="hover:text-lore-dark-950 transform transition duration-300 ease-in-out hover:shadow-lg" href={member.linkedin}><FaLinkedin /></a>}
            {member.github && <a className="hover:text-lore-dark-950 transform transition duration-300 ease-in-out hover:shadow-lg" href={member.github}><FaGithub /></a>}
        </div>
    </div>
);

export const Team = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <Navbar />
            <div id="content" className="flex flex-col bg-lore-light-100 justify-center items-center pt-28 pb-20">
                <div className="title relative text-7xl mb-10 text-lore-dark-950 font-dmserif text-center">
                    <h1 data-aos="fade-down" data-aos-duration="1000">MEET OUR <br/> <span className="text-lore-light-500 italic">TEAM</span><img src="assets/img/deco-title-1.png" alt=""/></h1>
                </div>
                <div className="card-list list-none flex flex-col md:flex-row justify-center items-center gap-6 py-8">
                    {teamMembers.map((member, index) => (
                        <TeamMember key={index} member={member} />
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

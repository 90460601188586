import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Landing } from "./modules/Landing";
import { Team } from './modules/Team';
import { Privacy } from './modules/Privacy';
import { Security } from './modules/Security';
import { Feedback } from './modules/Feedback';
import { Branding } from './modules/Branding';
import { Chat } from './modules/Chat';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Landing/>} />
      <Route path="/team" element={<Team/>} />
      <Route path="/privacy" element={<Privacy/>} />
      <Route path="/security" element={<Security/>} />
      <Route path="/feedback" element={<Feedback/>} />
      <Route path="/branding" element={<Branding/>} />
      <Route path="/chat" element={<Chat/>} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;

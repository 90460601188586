import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 500);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const Divider = () => (
    <div className="divider md:py-8 py-4 w-screen">
      <div className="border-b-2 border-b-primary w-full"></div>
    </div>
);

  return (
    <nav className={`navbar-custom navbar-sticky px-7 py-4 flex items-center justify-between z-50 ${isMenuOpen ? 'flex-col' : ''}`}>
      <div className="flex items-center justify-between w-full lg:w-auto">
      <a href="/">
      <img
        className="h-10"
        src={require("../assets/img/logo-text.png")}
        alt="Logo"
      />
      </a>
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-white">
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>
      </div>

      <ul className={`md:flex md:space-x-8 font-dmsans transition-all duration-300 ${isMenuOpen ? 'block' : 'hidden'}`}>
        {isMenuOpen && <Divider />}
        <li><a href="https://drive.google.com/file/d/1ldWDm75QDs1R21qwWrp-mk6PkxbXrh6g/view" download className={`${isSticky ? 'text-lore-dark-950' : 'text-white'} px-4`}>Unduh Aplikasi</a></li>
        <li><a href="/feedback" className={`${isSticky ? 'text-lore-dark-950' : 'text-white'} px-4`}>Dukungan</a></li>
        <li><a href="/chat" className={`${isSticky ? 'text-lore-dark-950' : 'text-white'} ${isMenuOpen ? 'block' : 'hidden'} px-4`}>Coba Free Chat</a></li>
      </ul>
      <a href="/chat"><button className="py-2 px-10 font-semibold bg-white text-lore-dark-950 rounded-3xl hidden md:flex items-center gap-3 font-dmsans">Mulai!</button></a>
    </nav>
  );
};

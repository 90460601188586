import React from "react";
import { Navbar } from "../components/NavBar";
import { Footer } from "../components/Footer";

export const Chat = () => {
    return (
        <>
        <Navbar />
            <div className="bg-lore-400 pt-28 md:p-28 flex flex-col justify-center items-center">
                <h1 className="font-dmserif tracking-wider text-3xl text-white mb-5">Try Free Chat Mode</h1>
                <iframe src="https://lorenika.vercel.app/" frameborder="0" className="h-screen w-full md:w-11/12"></iframe>
            </div>
        <Footer />
        </>
    );
}
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { LuDownload } from "react-icons/lu";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/NavBar";

export const Landing = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <>
            <Navbar />
            <div className="ld-content-1 h-screen overflow-auto bg-lore-dark-950 text-white text-center gap-5 flex flex-col justify-center items-center px-4 sm:px-8 md:px-16 lg:px-32 xl:px-48 2xl:px-64">
                <h1 data-aos="fade-up" className="font-dmserif text-4xl">Bayangkan sebuah aplikasi..</h1>
                <p data-aos="fade-up" data-aos-delay="500" className="font-dmsans text-xl">..dimana kamu bisa mengeksplorasi sejarah dan kebudayaan lokal indonesia secara interaktif seakan-akan kamu berbicara dengan budaya itu sendiri. Lorenika adalah platform yang memanjakanmu mengeksplorasi sejarah dan budaya dengan teknologi generative AI yang terkemuka</p>
                <a href="https://drive.google.com/file/d/1ldWDm75QDs1R21qwWrp-mk6PkxbXrh6g/view?usp=sharing"><button className="py-3 px-10 mt-3 font-semibold bg-white text-lore-dark-950 rounded-3xl flex items-center gap-3 font-dmsans transition-al hover:shadow-sm hover:shadow-lore-light-100"><LuDownload />Unduh Aplikasi</button></a>
                <a className="font-dmsans" href="/chat">Coba Free Chat</a>
            </div>

            <div className="ld-content-2 h-screen bg-lore-light-100 text-lore-dark-950 text-left gap-20 flex lg:flex-row flex-col justify-center items-center px-4 sm:px-8 md:px-16 lg:px-32 xl:px-48 2xl:px-64">
                <img data-aos="fade-left" className="h-96" src={require("../assets/img/travel.png")} alt="" />
                <div data-aos="fade-up" className="flex flex-col gap-5">
                    <h1 className="font-dmserif text-4xl">Eksplorasi sejarah dan budaya tidak pernah seaksesibel ini</h1>
                    <p className="font-dmsans text-xl">Lorenika akan mengajakmu eksplorasi budaya dengan cara yang efisien dan juga menyenangkan</p>
                </div>
            </div>

            <div className="ld-content-3 h-screen bg-lore-400 text-white text-left gap-20 flex lg:flex-row flex-col justify-center items-center px-4 sm:px-8 md:px-16 lg:px-32 xl:px-48 2xl:px-64">
                <div className="flex flex-col gap-5">
                    <h1 data-aos="fade-up" className="font-dmserif text-4xl">Dengan Generative AI, kamu seakan berbicara dengan sejarah dan budaya itu sendiri</h1>
                    <p data-aos="fade-left" className="font-dmsans text-xl">Nikmati pengalaman menyenangkan belajar dengan umpan balik real-time dari Generative AI</p>
                </div>
                <img src={require("../assets/img/lorenika-preview-2.png")} alt="" />
            </div>

            <div className="ld-content-4 h-screen bg-lore-light-100 text-lore-dark-950 text-left md:gap-20 flex lg:flex-row flex-col-reverse pt-64 md:pt-0 justify-center items-center px-4 sm:px-8 md:px-16 lg:px-32 xl:px-48 2xl:px-64">
                <img className="h-80" data-aos="fade-up" src={require("../assets/img/history.png")} alt="" />
                <div className="flex flex-col gap-5">
                    <h1 data-aos="fade-right" className="font-dmserif text-4xl">Rujukan data autentik dari komunitas pemerhati budaya, membuat aplikasi semakin kredibel</h1>
                    <p data-aos="fade-right" className="font-dmsans text-xl">Lorenika bekerja sama dengan komunitas pemerhati budaya untuk mendapatkan data kredibel</p>
                </div>
            </div>

            <div className="ld-content-5 h-screen bg-lore-light-100 text-lore-dark-950 text-left gap-7 flex flex-col justify-center items-center px-4 sm:px-8 md:px-16 lg:px-32 xl:px-48 2xl:px-64 pt-64 md:pt-0">
                <h1 data-aos="fade-up" className="font-dmserif text-4xl">Ayo kita eksplorasi sejarah dan budaya Indonesia!</h1>
                <p data-aos="fade-up" className="font-dmsans text-xl">Eksplorasi sejarah dan budaya lokal bersama Lorenika</p>
                <img src={require("../assets/img/lorenika-preview.png")} alt="" />
            </div>

            <div className="ld-content-5 h-72 bg-lore-second-300 text-lore-dark-950 text-center pt-10 lg:text-left gap-7 flex flex-col justify-center items-center">
                <h1 data-aos="fade-up" className="font-dmsans text-2xl lg:text-4xl">Siap memulai perjalanan Anda?</h1>
                <a href="https://drive.google.com/file/d/1ldWDm75QDs1R21qwWrp-mk6PkxbXrh6g/view" download><button data-aos-delay="500" data-aos="zoom-in" className="py-3 px-10 mt-3 font-semibold bg-lore-400 text-white rounded-3xl flex items-center gap-3 font-dmsans"><LuDownload />Unduh Aplikasi</button></a>
            </div>
            <Footer />
        </>
    )
}

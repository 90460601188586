import React from "react";
import { Navbar } from "../components/NavBar";
import { Footer } from "../components/Footer";

export const Privacy = () => {
    return (
        <>
            <Navbar />
            <div className="flex flex-col bg-lore-light-100">
                <div className="bg-lore-dark-950 pt-28 px-5 md:px-28">
                    <h1 className="text-4xl font-normal text-center mb-8 font-dmserif tracking-wider text-white">Privasi</h1>
                </div>
                
                <div className="px-5 md:px-28 pt-10">
                <section className="mb-8">
                    <h2 className="font-dmserif text-2xl font-normal mb-4">Pengantar</h2>
                    <p className="font-dmsans">
                        Di Lorenika, privasi Anda adalah prioritas utama kami. Kebijakan privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan melindungi informasi pribadi Anda ketika Anda menggunakan aplikasi kami.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif text-2xl font-normal mb-4">Informasi yang Kami Kumpulkan</h2>
                    <p className="font-dmsans">
                        Kami mengumpulkan informasi yang Anda berikan secara langsung, seperti nama, alamat email, dan informasi profil lainnya. Selain itu, kami juga mengumpulkan data otomatis seperti alamat IP, jenis perangkat, dan aktivitas penggunaan aplikasi.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif text-2xl font-normal mb-4">Penggunaan Informasi</h2>
                    <p className="font-dmsans">
                        Informasi yang kami kumpulkan digunakan untuk menyediakan, memelihara, dan meningkatkan layanan kami, serta untuk berkomunikasi dengan Anda. Kami juga menggunakan data ini untuk analisis dan penelitian guna meningkatkan pengalaman pengguna.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif text-2xl font-normal mb-4">Berbagi Informasi</h2>
                    <p className="font-dmsans">
                        Kami tidak akan membagikan informasi pribadi Anda kepada pihak ketiga tanpa persetujuan Anda, kecuali jika diperlukan untuk mematuhi hukum atau melindungi hak dan keselamatan kami serta pengguna kami.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif text-2xl font-normal mb-4">Keamanan Informasi</h2>
                    <p className="font-dmsans">
                        Kami menggunakan berbagai langkah keamanan teknis dan organisasi untuk melindungi informasi pribadi Anda dari akses yang tidak sah, penggunaan, atau pengungkapan. Namun, tidak ada metode transmisi data melalui internet atau penyimpanan elektronik yang sepenuhnya aman, jadi kami tidak dapat menjamin keamanan absolut.
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif text-2xl font-normal mb-4">Hak Pengguna</h2>
                    <p className="font-dmsans">
                        Anda memiliki hak untuk mengakses, memperbaiki, atau menghapus informasi pribadi Anda yang kami miliki. Jika Anda ingin menggunakan hak-hak ini, silakan hubungi kami di [email_kontak_privasi].
                    </p>
                </section>
                
                <section className="mb-8">
                    <h2 className="font-dmserif text-2xl font-normal mb-4">Perubahan Kebijakan Privasi</h2>
                    <p className="font-dmsans">
                        Kami dapat memperbarui kebijakan privasi ini dari waktu ke waktu. Kami akan memberi tahu Anda tentang perubahan signifikan dengan memposting kebijakan privasi yang baru di aplikasi kami. Anda disarankan untuk meninjau kebijakan privasi ini secara berkala untuk mengetahui informasi terbaru tentang praktik privasi kami.
                    </p>
                </section>
                
                {/* <section className="mb-8">
                    <h2 className="font-dmserif text-2xl font-normal mb-4">Kontak</h2>
                    <p className="font-dmsans">
                        Jika Anda memiliki pertanyaan atau kekhawatiran tentang kebijakan privasi ini atau praktik privasi kami, silakan hubungi kami di [email_kontak_privasi].
                    </p>
                </section> */}
                </div>
            </div>
            <Footer />
        </>
    );
}
